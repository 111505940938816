import React, { Component } from "react"
import SEO from "../components/seo"
import Menu from "../components/menu"

class ContactPage extends Component {
  render() {
    return (
      <>
        <SEO title="Contact" />
        <div className="contact-container">
          <div className="contact">
            <h1>Success!</h1>
            <p>I will reply as soon as i can!</p>
          </div>
        </div>
        <Menu active="contact" />
      </>
    )
  }
}

export default ContactPage
